import { Button, Form, Input, Switch, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import useApiPost from "../../../../hooks/useApiPost";
import { AppContext } from "../../../../App";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export interface inital {
  status: string;
  name: string;
  isActive?: boolean;
}

function VodacomProductForm() {
  const { statusId } = useParams<{ statusId: string }>();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { request } = useApiPost();
  const { projectId } = useContext(AppContext);
  const navigate = useNavigate();

  const [initalValues, setInitialValues] = useState<inital>({
    status: "",
    name: "",
  });
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const headerText = statusId
    ? "Edit Vodacom Product Status"
    : "Add Vodacom Product Status";

  const handleComeBack = () => navigate("/admin/vodacom-product-status");

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      const response = await request(
        "/office/product-status/toggleActive",
        "PATCH",
        {
          statusId: statusId,
          isActive: isActive,
        }
      );
      setIsLoading(false);
      if (response?.status === 200) {
        handleComeBack();
        toast.info(response?.message, {
          position: "top-right",
        });
      } else {
        if (response?.status === 400) {
          let error = response?.message?.error
            ? response?.message?.error
            : response?.message;
          toast.error(error, {
            position: "top-right",
          });
        } else {
          alert("something went wrong!!");
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddProduct = async (values: inital) => {
    try {
      setIsLoading(true);

      const payload = {
        project_id: projectId,
        status: values.status?.trim(),
        name: values.name?.trim(),
        is_active: isActive,
      };

      const response = await request(
        "/office/product-status/add",
        "POST",
        payload
      );

      if (response.status === 201) {
        handleComeBack();
        toast.success(response?.message, {
          position: "top-right",
        });
      } else {
        let error = response?.message?.error
          ? response?.message?.error
          : response?.message
          ? response?.message
          : "something went wrong!!";
        toast.error(error, {
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStatusData = async () => {
    setIsLoading(true);
    if (!statusId) {
      return;
    }

    const statusData = await request(`/office/product-status/list`, "POST", {
      projectId: projectId,
      statusId: statusId,
    });

    if (!statusData) {
      navigate(`/admin`);
      return;
    }

    setInitialValues({
      ...statusData.data,
      status: statusData.data.status.toString(),
      updatedAt: statusData.updated_at
        ? new Date(statusData.updated_at).toLocaleDateString()
        : "",
    });
    //form.setFieldsValue({name:statusData.name,status:statusData.status});
    setIsActive(statusData.data.is_active);
    setIsLoading(false);
  };

  useEffect(() => {
    if (statusId && projectId) {
      fetchStatusData();
    }
  }, [statusId, projectId]);

  useEffect(() => {
    form.setFieldsValue(initalValues);
  }, [initalValues]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  console.log(initalValues);

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        onFinish={(values) =>
          statusId ? handleUpdate() : handleAddProduct(values)
        }
        initialValues={initalValues}
      >
        <Form.Item
          label="Status"
          name={"status"}
          required
          rules={[
            {
              required: true,
              message: "Status is a required field.",
            },
            {
              message: "Please enter only numeric characters.",
              pattern: /^\s*\d+\s*$/,
            },
            {
              message: "Character length must be between 1 and 255.",
              max: 255,
              min: 0,
            },
          ]}
        >
          <Input disabled={statusId ? true : false} />
        </Form.Item>
        <Form.Item
          label="Name"
          name={"name"}
          required
          rules={[
            {
              required: statusId ? false : true,
              message: "Name is a required field.",
            },
            {
              message: "Please avoid using special characters.",
              pattern: /^\s*[\w\s_-]+\s*$/,
            },
            {
              message: "Character length must be between 1 and 255.",
              max: 255,
              min: 1,
            },
          ]}
        >
          <Input disabled={statusId ? true : false} />
        </Form.Item>
        <Form.Item label="Is active" name="isActive">
          <Switch checked={isActive} onChange={setIsActive} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default VodacomProductForm;
