const nameValidationRule = [
    {
        required: true,
        message: "Name is a required field.",
    },
    {
        message: "Please avoid using special characters.",
        pattern: /^\s*[\w\s_-]+\s*$/,
    },
    {
        message: "Character length must be between 1 and 255.",
        max: 255,
        min: 1,
    },
];
const nameValidationRuleSpaceFix = [
    {
        validator: (_, value) => {
            if (!value || value.trim() === "") {
                return Promise.reject(new Error("Please input name"));
            } else if (/\s{2,}/.test(value)) {
                return Promise.reject(new Error("Input cannot contain more than one consecutive space!"));
            }
            return Promise.resolve();
        },
    },
]
const OptionalnameValidationRule = [
    {
        message: "Please avoid using special characters.",
        pattern: /^\s*[\w\s_-]+\s*$/,
    },
    {
        message: "Character length must be between 1 and 255.",
        max: 255,
        min: 1,
    },
];
const startDateValidationRule = [{
        required: true,
        message: `Please select start date`,
    },
    ]
const endDateValidationRule = [
        {
            required: true,
            message: `Please select end date`,
        },
        ({ getFieldValue }) => ({
            validator(rule, value) {
                const start = new Date(getFieldValue("startDate"));
                const end = new Date(value);

                if (start > end) {
                    return Promise.reject(
                        "End date must be greater than start date"
                    );
                }
                return Promise.resolve();
            },
        }),
    ]
export { nameValidationRule, OptionalnameValidationRule, nameValidationRuleSpaceFix, startDateValidationRule, endDateValidationRule }