


import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toast } from "react-toastify";
import { Space, Table, Button, Input, Switch, InputRef, DatePicker } from "antd";
import useApiPost from "../../../../hooks/useApiPost";
import moment from "moment";
interface DataType {
  id: string;
  is_active: boolean;
  name: string;
  valid_from: string;
  valid_to: string;
  created_at: string;
  project_id: string;
}

type DataIndex = keyof DataType;
const dateFormat = "DD/MM/YYYY";
export interface APIProps {
  name?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  limit?: number;
  uploadDate?: string;
}

const VodacomDataList = () => {
  const { projectId } = useContext(AppContext);
  const { request } = useApiPost();
  const navigate = useNavigate();
  const location = useLocation();
  const [filterObj, setFilterObj] = useState({
    valid_from: undefined,
    valid_to: undefined,
    created_at: undefined,
    name: "",
  })

  const [isLoading, setIsLoading] = useState(false);
  const [vodacomDataList, setVodacomDataList] = useState<DataType[]>([]);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [totalCount, setTotalCount] = useState(1)

  const [searchData, setSearchData] = useState({
    valid_from: "",
    valid_to: "",
    created_at: "",
    name: "",
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    resetAllFilter()
  }, [projectId])

  useEffect(() => {
    getVodacomDataList({
      startDate:searchData?.valid_from,
      endDate:searchData?.valid_to,
      uploadDate: searchData?.created_at,
      name: searchData?.name,
      page: searchData?.page,
      limit: searchData?.limit
    })
  }, [projectId, searchData])

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Active / Inactive",
      dataIndex: "is_active",
      render: (val) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "Name"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Start Date",
      dataIndex: "valid_from",
      ...getColumnSearchProps("valid_from", `Date (${dateFormat})`),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "End Date",
      dataIndex: "valid_to",
      ...getColumnSearchProps("valid_to", `Date (${dateFormat})`),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Upload Date",
      dataIndex: "created_at",
      ...getColumnSearchProps("created_at", `Date (${dateFormat})`),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>
            Details
          </Button>
        </Space>
      ),
    },
  ];

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeholder: string
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        {dataIndex == "name" ? <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        /> : <DatePicker
          placeholder={placeholder}
          format={dateFormat}
          value={dataIndex == "valid_from" ? filterObj.valid_from : ( dataIndex == "valid_to" ? filterObj.valid_to: filterObj.created_at)}
          defaultValue={selectedKeys[0]}
          onKeyDown={(e) => {
            e.preventDefault();
            return false;
          }}
          style={{ marginBottom: 8, display: "block" }}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
            setFilterObj((prev) => ({ ...prev, [dataIndex]: date}));
          }}
          showToday={false}
        />}
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setFilterObj((prev) => ({ ...prev, [dataIndex]: (selectedKeys as string[])[0]}));
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[filterObj.name]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleDetails = async (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/import-vodacom-data/edit/${id}`);
  };

const resetAllFilter = () => {
    setFilterObj({
      valid_from: undefined,
      valid_to: undefined,
      created_at: undefined,
      name: ""
    })
    setSearchData({
      valid_from: "",
      valid_to: "",
      created_at: "",
      name: "",
      page: 1,
      limit: 10
    })
  }

  const handleTo = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/import-vodacom-data/new`);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    let tempText = selectedKeys[0] ? selectedKeys[0] : "";
    confirm();
    if (dataIndex == "name") {
      setFilterObj((prev) => ({ ...prev, [dataIndex]: tempText}));
    }
    setSearchedColumn(dataIndex);
    setSearchData((prev) => ({ ...prev, [dataIndex]: tempText }));
  };

  const handleReset = (clearFilters: () => void, searchType: string) => {
    clearFilters();
    setFilterObj((prev) => ({ ...prev, [searchType]: searchType == "name" ? "" : undefined }));
  };

  const getVodacomDataList = async ({
    name = "",
    startDate = "",
    endDate = "",
    uploadDate = "",
    page = 1,
    limit = 10,
  }: APIProps) => {
    setVodacomDataList([])
    setIsLoading(true)
    try {
      const payload = {
        projectId,
        name,
        startDate: filterObj.valid_from ? moment(startDate, dateFormat).format("YYYY-MM-DD") : "",
        endDate: filterObj.valid_to ? moment(endDate, dateFormat).format("YYYY-MM-DD") : "",
        uploadDate: filterObj.created_at ? moment(uploadDate, dateFormat).format("YYYY-MM-DD") : "",
        page,
        limit,
      };
      const res = await request("/office/product-import/list", "POST", payload);
      if (res?.status == 200) {
        const tempData = res?.data.map((item: DataType) => {
          return {
            ...item,
             valid_from:moment.utc(item.valid_from).format(dateFormat),
             valid_to:moment.utc(item.valid_to).format(dateFormat),
             created_at:moment.utc(item.created_at).format(dateFormat)

          };
        });
        setTotalCount(res?.total_count)
        setVodacomDataList(tempData)
      } else {
        toast.error(res?.message, {
          position: "bottom-center",
        });
      }
      
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Table
        size="large"
        key={projectId}
        columns={columns({
          onUpdate: handleDetails,
        })}
        dataSource={vodacomDataList}
        loading={isLoading}
        pagination={{
          total: totalCount,
          onChange(page, pageSize) {
            setSearchData(prev=>({ ...prev, page: page, limit: pageSize }));
          },
        }}
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
    </>
  );
};

export default VodacomDataList;
