import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Space, Table, Button, Input, Switch, InputRef } from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { AppContext } from "../../../../App";
import { DataType } from "./user-types";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useApiPost from "../../../../hooks/useApiPost";

type DataIndex = keyof DataType;

const UsersList = () => {
  const { projectId } = useContext(AppContext);
  const [, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState<DataType[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOnlyActiveVisible, setIsOnlyActiveVisible] = useState(false);
  const handleTo = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/user/new/`);
  };
  const { request, setError } = useApiPost();

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Active",
      dataIndex: "isActive",
      render: (val) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      ...getColumnSearchProps("firstName"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Surname",
      dataIndex: "lastName",
      ...getColumnSearchProps("lastName"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Role",
      dataIndex: "role",
      ...getColumnSearchProps("role"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Phone number",
      dataIndex: "contactNumber",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Region",
      dataIndex: "region",
      ...getColumnSearchProps("region"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>
            Update
          </Button>
          {/* <Button
            type="primary"
            danger
            onClick={() => props.onDelete(record.id)}
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];
  const searchInput = useRef<InputRef>(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
    const getAllUsers = async () => {
      setIsLoading(true);
      if (!projectId) {
        return;
      }
      // const users = await getUsers(projectId);
      const users = await request("/office/users", "POST", {
        projectId,
      });
      setAllUsers(users);
      setIsLoading(false);
    };
    getAllUsers();
  }, [projectId]);

  const handleUpdate = async (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/user/edit/${id}`);
  };

  // const handleDelete = async (id: string) => {
  //   // const res = await deleteUser(id);
  //   const res = await request(`/office/users/${id}`, "DELETE");
  //   if (res.status === 200) {
  //     const data = allUsers.filter((e) => e.id !== id);
  //     setAllUsers(data);
  //   }
  // };

  const handleToggleActive = () => {
    setIsOnlyActiveVisible(!isOnlyActiveVisible);
  };

  return (
    <>
      <div>
        <div
          style={{
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked
            onChange={() => handleToggleActive()}
          />
        </div>
      </div>
      <Table
        key={projectId}
        columns={columns({
          onUpdate: handleUpdate,
          // onDelete: handleDelete,
        })}
        dataSource={allUsers.filter((e) => e.isActive !== isOnlyActiveVisible)}
        rowKey="id"
        size="small"
        pagination={{ pageSize: 20 }}
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
    </>
  );
};

export default UsersList;
