import { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  Cascader,
  Switch,
  Modal,
} from "antd";
import { EMPTY_USER } from "../../../../utils/user";
import { AppContext } from "../../../../App";
import { DataType, User, IOption } from "../types";
import { DefaultOptionType } from "antd/lib/select";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import authCtx from "../../../../store/auth/AuthContextProvider";

// System Role field options type
type SystemRoleOption = {
  label: string;
  value: string;
};

const UserForm = () => {
  const { userId } = useParams<{ userId: string }>();
  const { projectId } = useContext(AppContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userRolesOptions, setUserRolesOptions] = useState<IOption[]>([]);
  const [userNetworkOptions, setUserNetworkOptions] = useState<IOption[]>([]);
  const [systemRoleOptions, setSystemRoleOptions] = useState<IOption[]>([]);
  const [treeData, setTreeData] = useState<DataType[]>([]);
  const { authState } = useContext(authCtx);

  const navigate = useNavigate();
  const headerText = userId ? "Edit User" : "Add User";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { request, setError } = useApiPost();
  const [projectsOptions, setProjectsOptions] = useState<[]>([]);
  const [projectAccess_id, setProjectAccess_id] = useState<[]>([]);
  const { access, role } = authState;

  const [formOptionValues, setFormOptionValues] = useState({
    hierarchy_id: null,
    contactNetwork_id: null,
    role_id: null,
    systemRole_id: null,
    projectAccess_id: [],
  });

  const [initValues, setInitValues] = useState<User>(EMPTY_USER);

  const hasAccess = (requiredAccess: string) => access.includes(requiredAccess);
  const hasAccessToSystemRole = () => hasAccess("Users") || role === "Admin";

  const fetchUserData = async () => {
    setIsLoading(true);
    if (!userId) {
      return;
    }
    const userData = await request(`/office/users/${userId}`, "POST", {
      projectId,
    });
    if (!userData) {
      navigate(`/admin`);
      return;
    }
    const res = await request("/office/projects/all");

    const options = res
      .filter((e: any) => e.isActive)
      .map((e: any) => ({
        label: e.description,
        value: e.id,
      }));
    setProjectAccess_id(userData.projectAccess_id);

    const {
      hierarchy_id,
      contactNetwork_id,
      role_id,
      systemRole_id,
      projectAccess_id = [],
    } = userData;
    setFormOptionValues({
      hierarchy_id,
      contactNetwork_id,
      role_id,
      systemRole_id,
      projectAccess_id,
    });
    setProjectsOptions(options);
    setProjectAccess_id(projectAccess_id);

    setInitValues({
      ...userData,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, [userId, projectId]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, userId]);

  const handleComeBack = () => navigate("/admin/users");

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      const { networks, roles, geography, systemRoles } = await request(
        "/office/users/options/creation/user",
        "POST",
        {
          project_id: projectId,
        }
      );

      // Filtered object with user role type Admin
      const _systemRoles = systemRoles?.filter(
        (option: SystemRoleOption) =>
          option.label === "Admin" && role !== "Admin" ? false : true
      );

      setTreeData(geography);
      setUserRolesOptions(roles);
      setUserNetworkOptions(networks);
      setSystemRoleOptions(_systemRoles);
      setIsLoading(false);
    };
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleInsert = async (values: any) => {
    try {
      const res = await request("/office/users/insert", "POST", values);
      if (res.error) {
        if (res.error === "Email already exists in the system") {
          setIsModalOpen(true);
        } else {
          alert(res.error);
        }
        setIsLoading(false);
        return;
      } else {
        handleComeBack();
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      // await updateUser(values);
      await request("/office/users/update", "POST", values);
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);

    const submitData = {
      ...values,
      ...formOptionValues,
      projectAccess_id,
      project_id: projectId,
    };

    if (userId) {
      handleUpdate({
        ...submitData,
        userId,
      });
    } else {
      handleInsert(submitData);
    }
  };

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event ? event.value : null,
    });
  };

  if (isLoading || userRolesOptions.length === 0 || treeData.length === 0) {
    return <p>Loading...</p>;
  }

  const displayRender = (labels: string[]) => {
    const label = labels[labels.length - 1];
    const labelText = getLabel(label);

    return labelText ? labelText : label;
  };
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const getLabel = (id: string) => {
    let label = "";
    const findLabel = (data: DataType[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    return label;
  };

  const handleLinkFirebase = async () => {
    await request("/office/users/firebase/create", "POST", {
      email: form.getFieldValue("email"),
    });
    await fetchUserData();
  };

  const handleOk = async () => {
    setIsLoading(true);
    const emailOfUser = form.getFieldValue("email");
    if (!emailOfUser) {
      return;
    }
    // await transferUserToAnotherProject(emailOfUser, projectId);
    await request("/office/users/reassign-project", "POST", {
      email: emailOfUser,
      role_id:formOptionValues?.role_id,
      hierarchy_id:formOptionValues?.hierarchy_id,
      projectId,
    });
    setIsModalOpen(false);
    setIsLoading(false);
    handleComeBack();
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Modal
        title="User transfer"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleModalCancel}
        centered
        footer={[
          <Button key="back" onClick={handleModalCancel} disabled={isLoading}>
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            disabled={isLoading}
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          This user already exists in the system. Do you want to transfer them
          to this project?
        </div>
      </Modal>

      <Form
        form={form}
        labelCol={{ span: 4 }}
        // wrapperCol={{ span: 10 }}
        onFinish={onFinish}
        initialValues={initValues}
      >
        <Form.Item label="Name" name="firstName">
          <Input />
        </Form.Item>
        <Form.Item label="Surname" name="lastName">
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input disabled={userId ? true : false} />
        </Form.Item>
        <Form.Item label="Alias(optional)" name="alias">
          <Input />
        </Form.Item>
        <Form.Item label="Contact Number" name="contactNumber">
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          hasFeedback
          name="role_id"
          rules={[{ required: true, message: "Role is required to proceed!" }]}
        >
          <Select
            loading={isLoading}
            options={userRolesOptions}
            defaultValue={formOptionValues.role_id}
            onChange={(_value, e) => {
              handleOptionChange("role_id", e);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Region"
          name="hierarchy_id"
          rules={[
            { required: true, message: "Region is required to proceed!" },
          ]}
        >
          <Cascader
            options={treeData}
            expandTrigger="hover"
            displayRender={displayRender}
            defaultValue={[getLabel(formOptionValues.hierarchy_id || "")]}
            onChange={(val) => onChange("hierarchy_id", val)}
            showSearch={{ filter }}
            changeOnSelect
          />
        </Form.Item>
        <Form.Item 
        label="Network"
        name="contactNetwork_id"
        >
          <Select
            loading={isLoading}
            options={userNetworkOptions}
            defaultValue={formOptionValues.contactNetwork_id}
            onChange={(_value, e) => {
              handleOptionChange("contactNetwork_id", e);
            }}
          />
        </Form.Item>
        <Form.Item label="UID" name="uid">
          <Input disabled />
        </Form.Item>
        {!form.getFieldValue("uid") && userId && (
          <Form.Item label="Firebase" name="uid">
            <Button onClick={() => handleLinkFirebase()}>Connect</Button>
          </Form.Item>
        )}
        <Form.Item label="ID" name="msUserCode_id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Manager e-mail"
          name="managerEmail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item label="Manager First Name" name="managerName">
          <Input />
        </Form.Item>
        <Form.Item label="Manager Last Name" name="managerSurname">
          <Input />
        </Form.Item>
        <Form.Item label="Is active" valuePropName="checked" name="isActive">
          <Switch defaultChecked={initValues.isActive} />
        </Form.Item>
        <Form.Item
          label="Is test account"
          valuePropName="checked"
          name="isTestAccount"
        >
          <Switch defaultChecked={initValues.isTestAccount} />
        </Form.Item>
        {hasAccessToSystemRole() && (
          <Form.Item label="System Role" name="systemRole_id">
            <Select
              allowClear
              loading={isLoading}
              options={systemRoleOptions}
              defaultValue={formOptionValues.systemRole_id}
              onChange={(_value, e) => {
                handleOptionChange("systemRole_id", e);
              }}
            />
          </Form.Item>
        )}
        <Form.Item label="Projects Access">
          <Select
            loading={isLoading}
            options={projectsOptions}
            value={projectAccess_id}
            mode="multiple"
            onChange={(e) => {
              setProjectAccess_id(e);
            }}
            allowClear
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserForm;
