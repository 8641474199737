import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import {
    Form,
    Button,
    Typography,
    Table,
    Switch,
} from "antd";
import useApiPost from "../../../../hooks/useApiPost";
import moment from "moment";
import xlsx from "json-as-xlsx";
import { toast } from "react-toastify";


interface DataType {
    id: string;
    l_account: boolean;
    product_code: string;
    qty_ordered: string;
    order_status: string;
    order_date: string;
    order_number: string;
}

const VodacomDataDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { request } = useApiPost();
    const [isLoading, setIsLoading] = useState(false);
    const [parentRecord, setParentRecord] = useState<any>({
        name: "",
        valid_from: "",
        valid_to: "",
        is_active: false
    });

    const navigate = useNavigate();
    const [isActive, setisActive] = useState<any>(false);
    const [vodacomDataList, setVodacomDataList] = useState<any[]>([]);

    useEffect(() => {
        getSelectedVodacomDataList()
    }, [])

    const getSelectedVodacomDataList = async () => {
        setIsLoading(true)
        try {
            const res = await request(`/office/product-import/details?import_parent_id=${id}`, "GET");
            if (res?.status == 200) {
                const { valid_from, valid_to, name, is_active } = res?.data?.parentRecord
                setParentRecord({
                    name,
                    is_active,
                    valid_from: moment.utc(valid_from).format("ddd MMM D YYYY"),
                    valid_to: moment.utc(valid_to).format("ddd MMM D YYYY")
                })
                setisActive(res?.data?.parentRecord?.is_active)
                const tempData = res?.data?.details.map((item: DataType) => {
                    return {
                        ...item,
                        order_date: moment.utc(item.order_date).format("DD/MM/YYYY")
                    };
                });
                setVodacomDataList(tempData)
            }
        }
        catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    }

    const columns = (): ColumnsType<DataType> => [
        {
            title: "L-Account",
            dataIndex: "l_account",
            render: (text) => <div>{text}</div>,
        },
        {
            title: "Product Code",
            dataIndex: "product_code",
            render: (text) => <div>{text}</div>,
        },
        {
            title: "Quantity Ordered",
            dataIndex: "qty_ordered",
            render: (text) => <div>{text}</div>,
        },
        {
            title: "Order Status",
            dataIndex: "order_status",
            render: (text) => <div>{text}</div>,
        },
        {
            title: "Order Date",
            dataIndex: "order_date",
            render: (text) => <div>{text}</div>,
        },
        {
            title: "Order Number",
            dataIndex: "order_number",
            render: (text) => <div>{text}</div>,
        }

    ];

    const saveToXlsx = (content: any) => {
        const columns: any = [{
            label: "L-Account",
            value: "l_account"
        },
        {
            label: "Product Code",
            value: "product_code"
        },
        {
            label: "Quantity Ordered",
            value: "qty_ordered"
        },
        {
            label: "Order Status",
            value: "order_status"
        },
        {
            label: "Order Date",
            value: "order_date"
        },
        {
            label: "Order Number",
            value: "order_number"
        }]
        xlsx(
            [
                {
                    columns,
                    content,
                },
            ],
            {
                fileName: parentRecord?.name ? parentRecord?.name : "Vodamcom Data",
                extraLength: 3,
            }
        );
    };

    const handleUpdate = async () => {
        try {
            const res = await request(`/office/product-import/update`, "PATCH", { import_parent_id: id });
            if (res?.status == 200) {
                toast.info(res?.message, {
                    position: "top-right",
                });
                navigate("/admin/import-vodacom-data")
            } else {
                toast.error(res?.message ? res?.message : "Something went wrong, Please try again later.", {
                    position: "top-right",
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    if (isLoading) return <div>Loading...</div>

    return (<>
        <Typography.Title>{parentRecord?.name}</Typography.Title>

        <div>
            <hr />
            <div>
                {parentRecord?.valid_from} - {parentRecord?.valid_to}
            </div>
            <hr />
        </div>

        <Table
            rowKey="order_number"
            style={{
                fontSize: "12px",
            }}
            pagination={{
                pageSize: 50,
            }}
            dataSource={vodacomDataList}
            columns={columns()}
        />

        <Form.Item label="Is Active" valuePropName="isActive" name="isActive">
            <Switch checked={isActive} onChange={(e) => setisActive(e)} disabled={!parentRecord?.is_active} />
        </Form.Item>

        {parentRecord?.is_active && <Button
            type="primary"
            style={{ margin: 10 }}
            onClick={handleUpdate}
        >
            Save
        </Button>}
        <Button
            type="primary"
            style={{ margin: 10 }}
            onClick={() => saveToXlsx(vodacomDataList)}
        >
            Export to Excel
        </Button>

    </>)
}

export default VodacomDataDetails